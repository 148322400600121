<template>
  <div id="lesson-management">
    <v-row no-gutters>
      <v-col md="12">
        <div class="pa-10 pt-5">
          <v-tabs class="tab-sadown mb-5" v-model="modelTab">
            <v-tab class="text-normal mb-0" @click="$store.state.announTab = 0">
              List of Announcements
            </v-tab>
            <v-tab class="text-normal mb-0" @click="$store.state.announTab = 1">
              List of Templates
            </v-tab>
          </v-tabs>
          <div class="pt-3">
            <v-tabs-items v-model="modelTab">
              <v-tab-item>
                <AnnouncementTab />
              </v-tab-item>
              <v-tab-item>
                <TemplateTab />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AnnouncementTab from '@/components/Announcement/AnnouncementTab.vue'
import TemplateTab from '@/components/Announcement/TemplateTab.vue'

export default {
  components: {
    AnnouncementTab,
    TemplateTab,
  },
  data() {
    return {
      modelTab: 0,
    }
  },
}
</script>
