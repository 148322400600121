<template>
  <div id="lesson-management">
    <v-row no-gutters>
      <v-col md="12">
        <div class="d-flex justify-space-between align-center mb-2">
          <h2>Announcement</h2>
          <div v-if="permission.cFull">
            <v-btn
              @click="$router.push({ name: 'create-announcement' })"
              color="primary"
              class="mr-3"
              >Create announcement</v-btn
            >
          </div>
        </div>
        <div class="d-flex gx-3">
          <date-picker
            :style="{ maxWidth: '250px' }"
            class="pt-4 mr-3"
            :model="fromDate"
            title="From Date"
            @update:model="updateFromDate"
          ></date-picker>
          <date-picker
            :style="{ maxWidth: '250px' }"
            class="pt-4"
            :model="toDate"
            title="To Date"
            @update:model="updateEndDate"
          ></date-picker>
        </div>

        <div class="pt-3">
          <div class="d-flex justify-space-between align-center mb-2">
            <h4>Total : {{ announFiltered.length }} records</h4>
          </div>
          <template v-if="isLoading">
            <div class="pa-6">
              <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </div>
          </template>
          <template>
            <v-data-table
              :headers="announHeaders"
              :items="announFiltered"
              v-if="!isLoading"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              hide-default-footer
              class="elevation-0 absent-table"
              :id="'absent-table'"
              fixed-header
              height="60vh"
            >
              <template v-slot:header="{ props }">
                <tr class="fixed-row-filter">
                  <th
                    v-for="header in props.headers"
                    :key="header.text"
                    :style="'width:' + header.width + '; min-width:' + header.width + ';'"
                  >
                    <div v-if="announFilters.hasOwnProperty(header.value)">
                      <Autocomplete
                        v-if="announFilters.hasOwnProperty(header.value)"
                        @setFilter="setAnounFilter"
                        :styleBorder="'border-bottom'"
                        :keyFilter="header.value"
                        :selectedInit="announFilters[header.value]"
                        :listItem="groupColumnValueList(header.value)"
                      />
                    </div>
                  </th>
                </tr>
              </template>
              <template v-slot:body="{ items }">
                <tbody v-if="items.length === 0" style="text-align: center">
                  <div
                    class="pt-3"
                    style="position: absolute; left: 50%; transform: translate(-50%, 0)"
                  >
                    No data available
                  </div>
                </tbody>
                <tbody v-for="(item, index) in items" :key="index">
                  <tr class="row-overall">
                    <td class="d-flex align-center justify-end">
                      <v-icon v-if="item.subAnnoun.length>0" class="mr-5" @click="changeHiddenSup(item.announcementID)">{{
                        listHidden.includes(item.announcementID)
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-down'
                      }}</v-icon>
                      <p>{{ itemsPerPage * (page - 1) + index + 1 }}</p>
                    </td>
                    <td>
                      {{ item.title }}
                    </td>
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <img
                            v-bind="attrs"
                            v-on="on"
                            class="ml-2 mr-2"
                            @click="viewAnnouncement(item)"
                            src="../../assets/images/icon-eye.svg"
                            alt=""
                            srcset=""
                          />
                        </template>
                        <span>View content</span>
                      </v-tooltip>
                    </td>
                    <td>
                      {{ item.announTags | hasValue }}
                    </td>
                    <td>
                      {{ item.announcementRepeatType }}
                    </td>
                    <td>
                      {{ item.startTime }}
                    </td>
                    <td>
                      {{ item.untilDate | getDate }}
                    </td>
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <p class="d-flex justify-center align-center" :style="{ width: '150px' }">
                            {{ item.receiverTypeNum + ' ' + item.receiverType }}

                            <img
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2 mr-2"
                              @click="viewRecipients(item)"
                              src="../../assets/images/icon-eye.svg"
                              alt=""
                              srcset=""
                            />
                          </p>
                        </template>
                        <span>View recipients</span>
                      </v-tooltip>
                    </td>

                    <td>
                      <p
                        v-if="item.announcementStatus"
                        :class="
                          item.announcementStatus === 'PENDING'
                            ? 'spanBox pendingSpan'
                            : item.announcementStatus === 'FINISHED'
                            ? ' spanBox finishedSpan'
                            : 'spanBox scheduleSpan'
                        "
                      >
                        {{ item.announcementStatus }}
                      </p>
                      <p v-else class="text-center">-</p>
                    </td>
                    <td>{{ item.updateBy }}</td>
                    <td>{{ item.updateAt }}</td>
                    <td>
                      <div class="d-flex align-center">
                        <v-tooltip v-if="permission.cFull || permission.cModify" top>
                          <template v-slot:activator="{ on, attrs }">
                            <img
                              class="pr-3"
                              v-bind="attrs"
                              v-on="on"
                              :style="{
                                opacity: item.announcementStatus === 'PENDING' ? '1' : '0.5',
                              }"
                              @click="
                                if (item.announcementStatus === 'PENDING') {
                                  $router.push({
                                    name: 'create-announcement',
                                    params: { announID: item.announcementID },
                                  })
                                }
                              "
                              src="../../assets/images/icon-edit-pen.svg"
                              alt="Edit icon"
                            />
                          </template>
                          <span>{{ item.announcementStatus === 'PENDING' ? 'Edit' : "Can't edit"}}</span>
                        </v-tooltip>
                        <v-tooltip v-if="permission.cFull" top>
                          <template v-slot:activator="{ on, attrs }">
                            <img
                              v-bind="attrs"
                              v-on="on"
                              @click="
                                $refs.confirmDelete.open(),
                                  (deleteAnnouncementID = item.announcementID)
                              "
                              src="../../assets/images/icon-delete.svg"
                              alt="Delete icon"
                            />
                          </template>
                          <span>Cancel</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                  <tr
                    class="row-sub-announ"
                    v-for="(item2, index) in item.subAnnoun"
                    :key="index"
                    v-show="listHidden.includes(item2.announcementID)"
                  >
                    <td></td>
                    <td>
                      {{ item2.announcementHisTittle }}
                    </td>
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <img
                            v-bind="attrs"
                            v-on="on"
                            class="ml-2 mr-2"
                            @click="viewAnnouncement(item)"
                            src="../../assets/images/icon-eye.svg"
                            alt="View icon"
                          />
                        </template>
                        <span>View content</span>
                      </v-tooltip>
                    </td>
                    <td colspan="2"></td>
                    <td>
                      {{ item2.sendTime | getDateTime }}
                    </td>
                    <td></td>
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <p class="d-flex justify-center align-center" :style="{ width: '150px' }">
                            {{ item2.actualReceiverNum + ' accounts' }}

                            <img
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2 mr-2"
                              @click="viewRecipients(item)"
                              src="../../assets/images/icon-eye.svg"
                              alt="View icon"
                            />
                          </p>
                        </template>
                        <span>View recipients</span>
                      </v-tooltip>
                    </td>
                    <td>
                      <p
                        v-if="item2.subAnnounStatus"
                        :class="
                          item2.subAnnounStatus === 'PENDING'
                            ? 'spanBox pendingSpan'
                            : item2.subAnnounStatus === 'FINISHED'
                            ? ' spanBox finishedSpan'
                            : 'spanBox scheduleSpan'
                        "
                      >
                        {{ item2.subAnnounStatus }}
                      </p>
                      <p v-else class="text-center">-</p>
                    </td>
                    <td colspan="3"></td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
            <div v-if="!isLoading" color="#fff" class="pl-10 mt-3 pr-10">
              <v-row>
                <v-col md="2">
                  <v-select
                    outlined
                    hide-details
                    dense
                    :value="itemsPerPage"
                    :items="[20, 50, 100, 200]"
                    @change="itemsPerPage = parseInt($event, 10)"
                    style="height: 32px"
                  ></v-select>
                </v-col>
                <v-col md="4" class="d-flex align-center justify-end">
                  <h3></h3>
                </v-col>
                <v-col md="6" class="d-flex justify-end">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                    elevation="0"
                    :total-visible="7"
                  ></v-pagination>
                </v-col>
              </v-row>
            </div>
          </template>
        </div>
        <popup-confirm
          @confirm="confirmDelete"
          :maxWidth="550"
          confirmText="Yes, cancel it!"
          ref="confirmDelete"
        >
          <v-card-title :class="'pt-0'" slot="icon">
            <div :style="{ margin: '0 auto' }">
              <img src="../../assets/images/icon-close-red.svg" alt="" srcset="" /></div
          ></v-card-title>
          <v-card-title :class="'pt-0 pb-0'" slot="title">
            <h2 :style="{ width: '100%' }" class="text-center">Confirm cancel</h2>
          </v-card-title>
          <v-card-title :class="'pt-0'" slot="content">
            <p :style="{ width: '100%' }" class="text-center">
              Are you sure to cancel this announcement? <br />
              You can not undo this action.
            </p>
          </v-card-title>
        </popup-confirm>
        <div v-if="dialogRecipients">
          <v-dialog content-class="elevation-0" width="800" v-model="dialogRecipients">
            <div :style="{ width: 'fit-content', marginLeft: 'auto' }">
              <img
                @click="dialogRecipients = false"
                :style="{ width: '30px', height: '30px', cursor: 'pointer ' }"
                src="../../assets/images/icon-close2.svg"
                alt=""
              />
            </div>
            <v-card style="aspect-ratio: 4/3" class="mt-5 px-5">
              <RecipientList :announID="selectedAnnouncement.announcementID" />
            </v-card>
          </v-dialog>
        </div>
        <div v-if="dialogAnnouncement">
          <v-dialog content-class="elevation-0" width="800" v-model="dialogAnnouncement">
            <div :style="{ width: 'fit-content', marginLeft: 'auto' }">
              <img
                @click="dialogAnnouncement = false"
                :style="{ width: '30px', height: '30px', cursor: 'pointer ' }"
                src="../../assets/images/icon-close2.svg"
                alt=""
              />
            </div>
            <v-card
              class="pt-5 pb-4"
              width="800"
              height="600"
              :style="{ overflowY: 'scroll', overflowX: 'hidden' }"
            >
              <v-card-title>
                <h1>{{ selectedAnnouncement.title }}</h1>
              </v-card-title>
              <v-card-title>
                <p v-html="selectedAnnouncement.context"></p>
              </v-card-title>
              <v-card-title>
                <p v-html="selectedAnnouncement.description"></p>
              </v-card-title>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
// import localeVi from 'dayjs/locale/vi'
import { announcement } from '@/api/announcement'

import RecipientList from '@/components/Announcement/RecipientList.vue'
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import PopupConfirm from '@/components/Utils/PopupConfirm.vue'
import { getObjectPermission } from '../../common/index'
import DatePicker from '@/components/Utils/DatePicker.vue'
import ViewDetail from '@/mixins/viewDetail.js'
import ConfigDate from '@/mixins/configDate.js'

export default {
  components: {
    Autocomplete,
    RecipientList,
    'popup-confirm': PopupConfirm,
    'date-picker': DatePicker,
  },
  mixins: [ViewDetail, ConfigDate],
  data() {
    return {
      listAnnoun: [],
      listHidden: [],
      isLoading: false,
      page: 1,
      dialogTemplate: false,
      selectedTemplate: null,
      dialogAnnouncement: false,
      selectedAnnouncement: null,
      dialogRecipients: null,
      deleteAnnouncementID: 0,
      pageCount: 0,
      permission: getObjectPermission(450),
      itemsPerPage: 100,
      dialogForm: false,
      fromDate: dayjs(new Date()).format('YYYY-MM-DD'),
      toDate: dayjs(new Date()).format('YYYY-MM-DD'),
      listTags: [],
      announHeaders: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Title',
          value: 'title',
          sortable: false,
          width: '200px',
        },
        {
          text: 'Content',
          value: 'context',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Tags',
          value: 'announTags',
          sortable: false,
          width: '120px',
        },
        {
          text: 'Type',
          value: 'announcementRepeatType',
          sortable: false,
          width: '60px',
        },
        {
          text: 'Sending time',
          value: 'startTime',
          sortable: false,
          width: '180px',
        },
        {
          text: 'Until date',
          value: 'untilDate',
          sortable: false,
          width: '80px',
        },
        {
          text: 'Recipients',
          value: 'receiverType',
          sortable: false,
          width: '160px',
        },
        {
          text: 'Status',
          value: 'announcementStatus',
          sortable: false,
          width: '140px',
        },
        {
          text: 'Update by',
          value: 'updateBy',
          sortable: false,
          width: '150px',
        },
        {
          text: 'Update at',
          value: 'updateAt',
          sortable: false,
          width: '180px',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          width: '80px',
        },
      ],
      announFilters: {
        title: [],
        announTags: [],
        receiverType: [],
        startTime: [],
        announcementStatus: [],
        announcementRepeatType: [],
        updateBy: [],
        updateAt: [],
      },
    }
  },
  computed: {
    announFiltered() {
      return this.listAnnoun?.filter(d => {
        return Object.keys(this.announFilters).every(f => {
          return (
            this.announFilters[f].length < 1 ||
            (f === 'announTags'
              ? this.announFilters[f].reduce((value, el) => {
                  if (d.announTags.includes(el)) {
                    value = true
                  }
                  return value
                }, false)
              : this.announFilters[f].includes(d[f]))
          )
        })
      })
    },
  },
  async created() {
    if (localStorage.getItem('announFromDate')) {
      this.fromDate = JSON.parse(localStorage.getItem('announFromDate'))
    }
    if (localStorage.getItem('announEndDate')) {
      this.toDate = JSON.parse(localStorage.getItem('announEndDate'))
    }
    if (localStorage.getItem('announFilter')) {
      this.filters = JSON.parse(localStorage.getItem('announFilter'))
    }
    const today = dayjs(new Date()).format('YYYY-MM-DD')
    await this.getCategoryList()
    if (this.fromDate === today && this.toDate === today) await this.getAllAnnoun()
  },

  watch: {
    fromDate: function (value) {
      localStorage.setItem('announFromDate', JSON.stringify(value))
      this.getAllAnnoun()
    },
    toDate: function (value) {
      localStorage.setItem('announEndDate', JSON.stringify(value))
      this.getAllAnnoun()
    },
  },
  methods: {
    /**
     * *Toggle sub announcement
     */
    changeHiddenSup(subItem) {
      if (this.listHidden.includes(subItem)) {
        this.listHidden = this.listHidden.filter(function (item) {
          return item !== subItem
        })
      } else {
        this.listHidden = [...this.listHidden, subItem]
      }
    },
    updateFromDate(event) {
      this.fromDate = event
    },
    updateEndDate(event) {
      this.toDate = event
    },
    confirmDelete() {
      this.deleteAnnoun()
    },
    async deleteAnnoun() {
      await announcement.cancelAnnouncement(this.deleteAnnouncementID).then(() => {
        this.getAllAnnoun()
      })
    },
    setAnounFilter(objectFilterChange) {
      this.announFilters = {
        ...this.announFilters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('announFilter', JSON.stringify(this.announFilters))
    },
    viewTemplate(template) {
      ;(this.dialogTemplate = true), (this.selectedTemplate = template)
    },
    viewAnnouncement(announcement) {
      ;(this.dialogAnnouncement = true), (this.selectedAnnouncement = announcement)
    },
    viewRecipients(announcement) {
      ;(this.dialogRecipients = true), (this.selectedAnnouncement = announcement)
    },
    groupColumnValueList(val) {
      if (val === 'announTags') {
        return [
          ...new Set(
            this.listAnnoun
              .reduce((a, c) => [...a, c[val]], [])
              .map(c => (c.includes(',') ? c.split(',') : c))
              .flat()
              .map(c => c.trim())
          ),
        ]
      }
      return this.listAnnoun.map(d => d[val]).filter(y => y)
    },
    async getCategoryList() {
      await announcement
        .getAllTags()
        .then(res => {
          this.listTags = res
        })
        .catch(() => {
          this.listTags = []
        })
    },
    async getAllAnnoun() {
      this.isLoading = true
      await announcement
        .getAllAnnouncement(this.fromDate, this.toDate)
        .then(res => {
          this.listAnnoun = res.map(announ => {
            const computedObject = { announcementID: announ.announcementID }
            return {
              ...announ,
              startTime: announ.startTime ? this.getTime(announ.startTime) : '',
              updateAt: announ.updateAt ? this.getTime(announ.updateAt) : '',
              announTags:
                announ.tagIDs.length > 0
                  ? this.listTags
                      .filter(e => announ.tagIDs.includes(e.anouncementTagID))
                      .map(e => e.anouncementTagName)
                      .join(', ')
                  : '-',
              subAnnoun: announ.subAnnoun.map(el => Object.assign(el, computedObject)),
            }
          })

          this.isLoading = false
        })
        .catch(() => {
          this.listAnnoun = []
        })
    },
    getTime(datetime) {
      const date = dayjs(datetime).format('DD/MM/YYYY')
      const time = dayjs(datetime).format('HH:mm')
      const timeTail = time.slice(0, 2) * 1 > 12 ? 'PM' : 'AM'
      return date + ' ' + time + ' ' + timeTail
    },
    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.close-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  top: 10px;
  right: 0;
}
::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(181, 181, 181);
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.row-overall {
  td {
    text-align: center;
  }
}
.row-sub-announ {
  background: #edf8ff;
  td {
    text-align: center;
  }
}
.spanBox {
  border-radius: 5px;
  padding: 3px 10px;
  margin: auto;
  width: 80%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.finishedSpan {
  background-color: #dbffee;
  color: #009b4a;
}
.pendingSpan {
  background: #fff6dd;
  color: #eb9516;
}
.scheduleSpan {
  background-color: #c8c8c8;
  color: #666666;
}
.pauseSpan {
  background-color: #343565;
  color: #dadada;
}
</style>
