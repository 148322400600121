var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mt-3"},[_vm._v("Recipient List")]),_c('h4',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.filtered.length > 0 ? ("Total : " + (_vm.filtered.length)) : 'No')+" accounts")]),(!_vm.loading)?_c('v-data-table',{staticClass:"elevation-0 absent-table",attrs:{"headers":_vm.headers,"items":_vm.filtered,"items-per-page":100,"page":_vm.page,"hide-default-footer":"","id":'absent-table',"fixed-header":"","height":"400px"},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('th',{key:header.text},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[(_vm.filters.hasOwnProperty(header.value))?_c('Autocomplete',{attrs:{"styleBorder":'border-bottom',"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_vm._e()])}),0)]}},{key:"item.no",fn:function(ref){
var index = ref.index;
return [_c('p',[_vm._v(_vm._s(100 * (_vm.page - 1) + index + 1))])]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.username))])]}},{key:"item.classCode",fn:function(ref){
var item = ref.item;
return [_c('p',{style:({ width: '150px' })},[_vm._v(_vm._s(_vm._f("hasValue")(item.classCode)))])]}},{key:"item.sendStatus",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(item.sendStatus))])]}},{key:"item.sendTime",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(_vm._f("getDateTime")(item.sendTime))+" ")])]}}],null,true)}):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"pl-10 mt-3 pr-10",attrs:{"color":"#fff"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"md":"6","offset":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"elevation":"0","total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }