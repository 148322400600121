<template>
  <div>
    <h1 class="mt-3">Recipient List</h1>
    <h4 class="mb-3">{{ filtered.length > 0 ? `Total : ${filtered.length}` : 'No' }} accounts</h4>
    <v-data-table
      :headers="headers"
      :items="filtered"
      :items-per-page="100"
      @page-count="pageCount = $event"
      :page.sync="page"
      v-if="!loading"
      hide-default-footer
      class="elevation-0 absent-table"
      :id="'absent-table'"
      fixed-header
      height="400px"
    >
      <template v-slot:header="{ props }">
        <tr class="fixed-row-filter">
          <th v-for="header in props.headers" :key="header.text">
            <div v-if="filters.hasOwnProperty(header.value)">
              <Autocomplete
                v-if="filters.hasOwnProperty(header.value)"
                @setFilter="setFilter"
                :styleBorder="'border-bottom'"
                :keyFilter="header.value"
                :selectedInit="filters[header.value]"
                :listItem="groupColumnValueList(header.value)"
              />
            </div>
          </th>
        </tr>
      </template>
      <template v-slot:[`item.no`]="{ index }">
        <p>{{ 100 * (page - 1) + index + 1 }}</p>
      </template>
      <template v-slot:[`item.username`]="{ item }">
        <p>{{ item.username }}</p>
      </template>
      <template v-slot:[`item.classCode`]="{ item }">
        <p :style="{ width: '150px' }">{{ item.classCode | hasValue }}</p>
      </template>
      <template v-slot:[`item.sendStatus`]="{ item }">
        <p class="text-center">{{ item.sendStatus }}</p>
      </template>
      <template v-slot:[`item.sendTime`]="{ item }">
        <p>
          {{ item.sendTime | getDateTime }}
        </p>
      </template>
    </v-data-table>
    <div v-if="!isLoading" color="#fff" class="pl-10 mt-3 pr-10">
      <v-row>
        <v-col md="6" offset="6" class="d-flex justify-end">
          <v-pagination
            v-model="page"
            :length="pageCount"
            elevation="0"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
// import dayjs from 'dayjs'
// import localeVi from 'dayjs/locale/vi'
import { announcement } from '@/api/announcement'
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import { getObjectPermission } from '../../common/index'
import ViewDetail from '@/mixins/viewDetail.js'
import ConfigDate from '@/mixins/configDate.js'
export default {
  components: { Autocomplete },
  props: {
    announID: {
      type: Number,
    },
  },
  mixins: [ViewDetail, ConfigDate],
  data() {
    return {
      modelTab: '',
      isLoading: false,
      permission: getObjectPermission(405),
      dialogForm: false,
      recipientList: [],
      page:1,
      pageCount:0,
      headers: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
        },
        {
          text: 'Name',
          value: 'username',
          sortable: false,
        },
        {
          text: 'Class code',
          value: 'classCode',
          sortable: false,
        },
        {
          text: 'Send Status',
          value: 'sendStatus',
          sortable: false,
        },
        {
          text: 'Send Time',
          value: 'sendTime',
          sortable: false,
        },
      ],
      filters: {
        targetName: [],
        targetType: [],
        targetCenter: [],
        targetClass: [],
      },
    }
  },
  computed: {
    filtered() {
      return this.recipientList?.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f])
        })
      })
    },
  },
  created() {
    this.init()
  },

  watch: {},
  methods: {
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('recipientFilter', JSON.stringify(this.recipientFilters))
    },
    groupColumnValueList(val) {
      return this.recipientList.map(d => d[val]).filter(y => y)
    },
    async init() {
      this.loading = true
      this.recipientList = await announcement.getAnnouncementUsers(this.announID)
      this.loading = false
    },
  },
}
</script>
<style lang="scss" scoped>
.spanBox {
  border-radius: 5px;
  padding: 3px 10px;
  width: 70%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sentSpan {
  background-color: #dbffee;
  color: #009b4a;
}
.pendingSpan {
  background: #fff6dd;
  color: #eb9516;
}
.scheduleSpan {
  background-color: #cf4e4e1a;
  color: #e11c1c;
}
</style>
