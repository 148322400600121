<template>
  <div>
    <div>
      <div class="d-flex justify-space-between align-center mb-2">
          <h2>Template</h2>
        </div>
      <div class="d-flex justify-space-between align-center mb-2">
        <h4>Total : {{ templateFiltered.length }} records</h4>
      </div>
      <template v-if="isLoading">
        <div class="pa-6">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
      </template>
      <template>
        <v-data-table
          :headers="templateHeaders"
          :items="templateFiltered"
          v-if="!isLoading"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          hide-default-footer
          class="elevation-0 absent-table"
          :id="'absent-table'"
          fixed-header
          height="60vh"
        >
          <template v-slot:header="{ props }">
            <tr class="fixed-row-filter">
              <th
                v-for="header in props.headers"
                :key="header.text"
                :style="'width:' + header.width + '; min-width:' + header.width + ';'"
              >
                <div v-if="templateFilters.hasOwnProperty(header.value)">
                  <Autocomplete
                    v-if="templateFilters.hasOwnProperty(header.value)"
                    @setFilter="setTemplateFilter"
                    :styleBorder="'border-bottom'"
                    :keyFilter="header.value"
                    :selectedInit="templateFilters[header.value]"
                    :listItem="groupColumnValueList(header.value)"
                  />
                </div>
              </th>
            </tr>
          </template>
          <template v-slot:[`item.no`]="{ index }">
            <p>{{ itemsPerPage * (page - 1) + index + 1 }}</p>
          </template>
          <template v-slot:[`item.templateTitle`]="{ item }">
            <p style="width: 150%" @click="viewTemplate(item.templateID)">
              {{ item.templateTitle }}
            </p>
          </template>
          <template v-slot:[`item.tagIDs`]="{ item }">
            <p>{{ item.tagIDs }}</p>
          </template>
          <template v-slot:[`item.updateBy`]="{ item }">
            <p class="text-center">{{ item.updateBy }}</p>
          </template>
          <template v-slot:[`item.createBy`]="{ item }">
            <p class="link" @click="viewAccount(item.createUserID)">
              {{ item.createBy }}
            </p>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div class="d-flex align-center">
              <v-tooltip v-if="permission.cFull || permission.cModify" top>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2 mr-2"
                    @click="viewTemplate(item)"
                    src="../../assets/images/icon-eye.svg"
                    alt="View icon"
                  />
                </template>
                <span>View Detail</span>
              </v-tooltip>
              <v-tooltip v-if="permission.cFull || permission.cModify" top>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    class="pr-3"
                    v-bind="attrs"
                    v-on="on"
                    @click="editTemplate(item.templateID)"
                    src="../../assets/images/icon-edit-pen.svg"
                    alt="Edit icon"
                  />
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip v-if="permission.cFull" top>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    v-bind="attrs"
                    v-on="on"
                    @click="$refs.confirmDelete.open(), (deleteTemplateID = item.templateID)"
                    src="../../assets/images/icon-delete.svg"
                    alt="Delete icon"
                  />
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div v-if="!isLoading" color="#fff" class="pl-10 mt-3 pr-10">
          <v-row>
            <v-col md="2">
              <v-select
                outlined
                hide-details
                dense
                :value="itemsPerPage"
                :items="[20, 50, 100, 200]"
                @change="itemsPerPage = parseInt($event, 10)"
                style="height: 32px"
              ></v-select>
            </v-col>
            <v-col md="4" class="d-flex align-center justify-end">
              <h3></h3>
            </v-col>
            <v-col md="6" class="d-flex justify-end">
              <v-pagination
                v-model="page"
                :length="pageCount"
                elevation="0"
                :total-visible="7"
              ></v-pagination>
            </v-col>
          </v-row>
        </div>
      </template>
    </div>
    <popup-confirm
      @confirm="confirmDelete"
      :maxWidth="550"
      confirmText="Yes, delete it!"
      ref="confirmDelete"
    >
      <v-card-title :class="'pt-0'" slot="icon">
        <div :style="{ margin: '0 auto' }">
          <img src="../../assets/images/icon-close-red.svg" alt="" srcset="" /></div
      ></v-card-title>
      <v-card-title :class="'pt-0 pb-0'" slot="title">
        <h2 :style="{ width: '100%' }" class="text-center">Confirm delete</h2>
      </v-card-title>
      <v-card-title :class="'pt-0'" slot="content">
        <p :style="{ width: '100%' }" class="text-center">
          Are you sure to delete this template? <br />
          You can not undo this action.
        </p>
      </v-card-title>
    </popup-confirm>
    <div v-if="dialogTemplate">
      <v-dialog content-class="elevation-0" width="1000px" v-model="dialogTemplate">
        <v-card
          class="pt-5 pb-4"
          width="1000"
          height="600"
          :style="{ overflowY: 'scroll', overflowX: 'hidden' }"
        >
          <div class="pt-5 pb-4" :style="{ overflowY: 'scroll', overflowX: 'hidden' }">
            <v-card-title>
              <h2>{{ selectedTemplate.templateTitle }}</h2>
              <img
                @click="dialogTemplate = false"
                class="close-icon"
                src="../../assets/images/icon-close2.svg"
                alt=""
              />
            </v-card-title>
            <v-card-title>
              <p class="mb-3" v-html="selectedTemplate.templateContext"></p>
              <p v-html="selectedTemplate.description"></p>
            </v-card-title>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
// import localeVi from 'dayjs/locale/vi'
import { announcement } from '@/api/announcement'

import Autocomplete from '@/components/Utils/Autocomplete.vue'
import PopupConfirm from '@/components/Utils/PopupConfirm.vue'
import { getObjectPermission } from '../../common/index'
import ViewDetail from '@/mixins/viewDetail.js'
import ConfigDate from '@/mixins/configDate.js'

export default {
  components: {
    Autocomplete,
    'popup-confirm': PopupConfirm,
  },
  mixins: [ViewDetail, ConfigDate],
  data() {
    return {
      listTemplate: [],
      isLoading: false,
      page: 1,
      dialogTemplate: false,
      selectedTemplate: null,
      pageCount: 0,
      permission: getObjectPermission(450),
      itemsPerPage: 100,
      dialogForm: false,
      listTags: [],
      templateHeaders: [
        {
          text: 'No',
          value: 'no',
          width: '50px',
          sortable: false,
        },
        {
          text: 'Template Title',
          value: 'templateTitle',
          width: '50%',
          sortable: false,
        },
        {
          text: 'Tags',
          value: 'tagIDs',
          width: '200px',
          sortable: false,
        },
        {
          text: 'Last update by',
          value: 'updateBy',
          width: '50px',
          sortable: false,
        },
        {
          text: 'Update at',
          value: 'updateAt',
          width: '50px',
          sortable: false,
        },
        {
          text: 'Action',
          value: 'action',
          width: '50px',
          sortable: false,
        },
      ],
      deleteTemplateID: 0,
      templateFilters: {
        templateTitle: [],
        tagIDs: [],
        updateBy: [],
        updateAt: [],
      },
    }
  },
  computed: {
    templateFiltered() {
      return this.listTemplate?.filter(d => {
        return Object.keys(this.templateFilters).every(f => {
          return (
            this.templateFilters[f].length < 1 ||
            (f === 'tagIDs'
              ? this.templateFilters[f].reduce((value, el) => {
                  if (d.tagIDs.includes(el)) {
                    value = true
                  }
                  return value
                }, false)
              : this.templateFilters[f].includes(d[f]))
          )
        })
      })
    },
  },
  async created() {
    if (localStorage.getItem('templateFilter')) {
      this.filters = JSON.parse(localStorage.getItem('templateFilter'))
    }
    await this.getCategoryList()
    await this.getAllTemplate()
  },

  methods: {
    confirmDelete() {
      this.deleteTemplate()
    },
    async deleteTemplate() {
      await announcement.deleteTemplate(this.deleteTemplateID).then(() => {
        this.getAllTemplate()
      })
    },
    setTemplateFilter(objectFilterChange) {
      this.templateFilters = {
        ...this.templateFilters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('templateFilter', JSON.stringify(this.templateFilters))
    },
    viewTemplate(template) {
      ;(this.dialogTemplate = true), (this.selectedTemplate = template)
    },
    editTemplate(id) {
      this.$router.push({
        name: 'create-template',
        query: {
          templateID: id,
        },
      })
    },
    groupColumnValueList(val) {
      if (val === 'tagIDs') {
        return [
          ...new Set(
            this.listTemplate
              .reduce((a, c) => [...a, c[val]], [])
              .map(c => (c?.includes(',') ? c.split(',') : c))
              .flat()
              .map(c => c?.trim())
          ),
        ]
      }
      return this.listTemplate.map(d => d[val]).filter(y => y)
    },
    async getCategoryList() {
      await announcement
        .getAllTags()
        .then(res => {
          this.listTags = res
        })
        .catch(() => {
          this.listTags = []
        })
    },
    async getAllTemplate() {
      this.isLoading = true
      await announcement
        .getAllTemplate()
        .then(res => {
          this.listTemplate = res.map(el => {
            return {
              ...el,
              updateAt: el.updateAt ? dayjs(el.updateAt).format('DD/MM/YYYY') : '',
              tagIDs:
                el.tagIDs.length > 0
                  ? this.listTags
                      .filter(e => el.tagIDs.includes(e.anouncementTagID))
                      .map(e => e.anouncementTagName)
                      .join(', ')
                  : '',
            }
          })
          this.isLoading = false
        })
        .catch(() => {
          this.listTemplate = []
        })
    },
    getTime(datetime) {
      const date = dayjs(datetime).format('DD/MM/YYYY')
      const time = dayjs(datetime).format('HH:mm')
      const timeTail = time.slice(0, 2) * 1 > 12 ? 'PM' : 'AM'
      return date + ' ' + time + ' ' + timeTail
    },
  },
}
</script>
<style lang="scss" scoped>
.close-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  top: 10px;
  right: 0;
}
::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(181, 181, 181);
  border-radius: 10px;
  border: 3px solid #ffffff;
}
</style>
